import { render, staticRenderFns } from "./findArticle.vue?vue&type=template&id=27b44f86&scoped=true&"
import script from "./findArticle.vue?vue&type=script&lang=js&"
export * from "./findArticle.vue?vue&type=script&lang=js&"
import style0 from "./findArticle.vue?vue&type=style&index=0&id=27b44f86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b44f86",
  null
  
)

export default component.exports